<template>
  <div class="home">
    <div class="container is-fluid" v-if="user">
      <page-header :entity="$EntitiesName.User" :edit="edit" :save="save" :cancel="cancel" :remove="user && user.active ? remove : null">
        <h1 class="title">
          <breadcrumb location="/users" text="Utilisateur"></breadcrumb>
          <span data-test="sel-username">{{ user.name }}</span>
        </h1>
      </page-header>

      <div class="panel infos">
        <div class="panel-heading has-background-primary">Informations</div>
        <div class="panel-block is-block no-border">
          <text-field label="Nom" v-model="user.name" required></text-field>
          <text-field label="E-Mail" v-model="user.mail" required></text-field>
          <text-field label="Téléphone" v-model="user.phone"></text-field>
          <many2one-field
            label="Responsable"
            v-model="user.manager"
            :fetch="$Api.User.fetchUsers"
            reference="name"
            @click="(item) => $router.push('/user/' + item.id)"
            :columns="columns"
            :edit="editMode"
          ></many2one-field>
          <selector-field
            label="Profil"
            v-model="user.role"
            :options="$Api.Role.fetchRolesAsList"
            required
            data-test="sel-user-role"
          >
            <template slot-scope="{ option }">{{ option.name }}</template>
          </selector-field>
          <text-field label="Service" v-model="user.service"></text-field>
          <text-field label="Qualité" v-model="user.quality"></text-field>
          <checkbox-field label="Actif ?" v-model="user.active"/>
        </div>
      </div>

      <div class="panel">
        <panel-header
          title="Projets"
          @add="addProject"
          :fetch="$Api.Project.fetchProjects"
          :columns="columnsProject"
        >
          <button
            v-if="getRights($EntitiesName.UserProject).create"
            slot="activator"
            data-test="sel-user-add-project"
            class="button is-small is-success"
          >
            <font-awesome-icon icon="plus" />
          </button>
        </panel-header>
        <div class="panel-block">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Nom du RIP</th>
                <th>OTP</th>
                <th>Responsable Projet</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(p, index) in projects" :key="index">
                <td>{{ p.name }}</td>
                <td><span v-for="(otp) in p.otps" :key="'i_' + otp.eotp" class="tag is-info is-light">{{ otp.otp }}</span></td>
                <td>{{ p.owner && p.owner.name }}</td>
                <td>
                  <div class="is-pulled-right">
                    <button
                      v-if="getRights($EntitiesName.Project).read"
                      class="button is-small is-info"
                      @click="editProject(p)"
                    >
                      <font-awesome-icon icon="search" />
                    </button>
                    <button
                      v-if="getRights($EntitiesName.UserProject).delete"
                      class="button is-small is-danger"
                      @click="removeProject(p)"
                    >
                      <font-awesome-icon icon="trash-alt" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  props: ['id'],
  name: 'user',
  data() {
    return {
      user: {},
      projects: [],
      companies: [],
      columns: {
        name: {
          title: 'Nom',
          filter: '',
        },
        mail: {
          title: 'E-Mail',
          filter: '',
        },
        'role.name': 'Role',
        service: {
          title: 'Service',
          filter: '',
        },
      },
      columnsProject: {
        name: {
          title: 'Nom',
          filter: '',
        },
        code: {
          title: 'Code',
          filter: '',
        },
        'type.name': {
          title: 'Type',
          filter: '',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      editMode: 'App/isEdition',
      getRights: 'Auth/getRights',
    }),
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  methods: {
    fetchData() {
      axios.get(`/users/${this.id}`).then((response) => {
        this.user = response.data;
        this.$store.dispatch('App/setEditing', false);
      });

      axios.get(`/users/${this.id}/projects`).then((response) => {
        this.projects = response.data;
      });
    },
    save() {
      return axios.put(`/users/${this.id}`, this.user).then((response) => {
        if (response.data.success) {
          this.$store.dispatch('App/setEditing', false);
        }
      });
    },
    edit() {
      this.$store.dispatch('App/setEditing', true);
    },
    cancel() {
      this.fetchData();
    },
    remove() {
      return this.$awn.confirm(
        'Êtes-vous sûr de vouloir désactiver cet utilisateur ?',
        () =>
          axios.delete(`/users/${this.id}`, this.user).then((response) => {
            if (response.data.success) {
              this.$router.replace('/users');
            }
          }),
      );
    },
    /* Projects */
    addProject(project) {
      axios.post(`/projects/${project.id}/user/${this.user.id}`).then(() => {
        this.fetchData();
      });
    },
    removeProject(project) {
      this.$awn.confirm(
        'Êtes-vous sûr de vouloir supprimer cet élément ?',
        () => {
          axios
            .delete(`/projects/${project.id}/user/${this.user.id}`)
            .then(() => {
              this.fetchData();
            });
        },
      );
    },
    editProject(p) {
      this.$router.push(`/project/${p.id}`);
    },
  },
};
</script>

<style lang='scss' scoped>
</style>
